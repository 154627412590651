import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { EmbeddedMdFile, Seo } from "../components";

const Landing = () => {
  return (
    <>
      <Seo
        metaTitle="Home"
        metaDescription="Home page"
        canonical={window.location.href}
      />
      <Container fixed>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          minHeight="calc(100vh - 150px - 75px)"
        >
          <Avatar
            sx={{ width: 200, height: 200, mt: 10 }}
            alt="Kristiyan Strahilov"
            src="https://gravatar.com/avatar/b0bfc3fdae40a8f059d4b5a6b7ce00d7fbfb7e70fbf1033c5297ed4afed3cdd2.jpg?s=200"
          >
            KS
          </Avatar>
          <Typography
            variant="h4"
            textAlign="center"
            gutterBottom
            marginTop="10px"
            component="div"
          >
            Kristiyan Strahilov
          </Typography>

          <EmbeddedMdFile
            filePath={
              "https://raw.githubusercontent.com/kpuc00/kpuc00/main/README.md"
            }
          />
        </Box>
      </Container>
    </>
  );
};

export default Landing;

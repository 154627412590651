import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button, Box } from "@mui/material";
import { Seo } from "../components";

const NotFound = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <>
      <Seo metaTitle="404" />
      <Container fixed>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          minHeight="calc(100vh - 150px - 75px)"
        >
          <Typography variant="h1" component="div" gutterBottom sx={{ mt: 10 }}>
            404
          </Typography>
          <Typography variant="h4" component="div" gutterBottom>
            Page Not Found
          </Typography>
          <Typography variant="body1" gutterBottom>
            The page you are looking for doesn't exist or has been moved.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleGoBack()}
          >
            Go to Home
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default NotFound;

import React from "react";
import Router from "./Router";
import { Footer, Navigation } from "./components";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Router />
      <Footer />
    </div>
  );
}

export default App;

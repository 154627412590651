import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Seo } from "../components";

const Projects = () => {
  return (
    <>
      <Seo
        metaTitle="Projects"
        metaDescription="Projects page"
        canonical={window.location.href}
      />
      <Container fixed>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          minHeight="calc(100vh - 150px - 75px)"
        >
          <Typography
            variant="h4"
            textAlign="center"
            gutterBottom
            sx={{ mt: 10 }}
            component="div"
          >
            Projects
          </Typography>

          <Typography
            variant="h5"
            textAlign="center"
            gutterBottom
            sx={{ mt: 10 }}
            component="div"
          >
            Coming soon!
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default Projects;

import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ROUTES = [
  { link: "/", title: "Home" },
  { link: "/projects", title: "Projects" },
];

const Navigation = () => {
  const navigate = useNavigate();
  const handleClick = (route) => {
    navigate(route);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      gap="10px"
      padding="20px"
      height="75px"
    >
      {ROUTES.map((route) => (
        <Typography
          fontSize="16px"
          variant="button"
          display="block"
          gutterBottom
          color={window.location.pathname === route.link ? "primary" : "text"}
          onClick={() => handleClick(route.link)}
        >
          {route.title}
        </Typography>
      ))}
    </Box>
  );
};

export default Navigation;

import React from "react";
import { Helmet } from "react-helmet";

const Seo = (props) => {
  const { metaTitle, metaDescription, canonical } = props;

  return (
    <Helmet>
      {metaTitle && <title>{metaTitle}</title>}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};
export default Seo;

import { Routes, Route } from "react-router-dom";
import { Landing, NotFound, Projects } from "./pages";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default Router;

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const EmbeddedMdFile = ({ filePath }) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => setContent(text))
      .catch((e) => {
        console.log("Error while loading text.", e);
      });
  }, [filePath]);

  return (
    <Box sx={{ pt: 2, pb: 2 }}>
      <ReactMarkdown
        children={content}
        remarkPlugins={[remarkGfm]}
        skipHtml={true}
      />
    </Box>
  );
};

export default EmbeddedMdFile;
